<!-- copy of areas -->

<template>
  <div class="content-wrapper">
    <h1 class="mb-5">Prodotti</h1>
    <b-card class="mb-4">
      <b-row class="my-1">
        <b-col lg="3">
          <b-input-group size="sm" class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
            ></b-form-input>
          </b-input-group>
        </b-col>

        <b-col class="text-lg-right">
          <b-button size="sm" class="mb-2" v-b-toggle.sidebar-product>
            <b-icon icon="plus" aria-hidden="true"></b-icon> New Option
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <!-- <b-overlay :show="items == null" rounded="sm" class="overlay-placeholder"> -->
    <b-overlay rounded="sm" class="overlay-placeholder">
      <b-card>
        <div v-if="loadingItems" class="w-100">
          <SkeletonTable></SkeletonTable>
        </div>
        <b-table
          v-else
          hover
          :items="filteredItems"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
        >
          <template #cell(immagineProdotto)="row">
            <div style="max-width: 100px">
              <img :src="row.item.immagineProdotto" class="w-100" />
            </div>
          </template>

          <template #cell(actions)="row">
            <b-dropdown
              size="sm"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <b-icon-gear-fill></b-icon-gear-fill>
              </template>
              <b-dropdown-item
                href="#"
                v-b-toggle.sidebar-product
                @click="
                  currentItem = row.item;
                  tmpItem = JSON.parse(JSON.stringify(row.item));
                "
                >Edit</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                v-if="row.item.attiva"
                @click="row.item.attiva = !row.item.attiva"
                >Block</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="
                  currentItem = row.item;
                  showDeleteModal = true;
                "
                >Delete</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="mt-4 mb-1"
        ></b-pagination>
      </b-card>
    </b-overlay>
    <b-sidebar
      class="right-sidebar"
      id="sidebar-product"
      right
      shadow
      lazy
      @hidden="resetData"
    >
      <div class="content-wrapper  my-3 mx-1">
        <h4 v-if="tmpItem && tmpItem.nome">
          {{ tmpItem.nome }}
        </h4>
        <h4 v-else>New Prodotto</h4>

        <b-row>
          <b-col>
            <div
              class="upload-photo-cicrle mt-2 mb-4"
              v-bind:style="{
                backgroundImage: 'url(' + backgroundImage() + ')',
              }"
            >
              <b-form-file
                class="button-file photo-button"
                v-model="tmpImage"
                :state="Boolean(tmpImage)"
                accept="image/*"
              >
                <template #placeholder>
                  <b-icon-camera-fill />
                </template>
                <template #file-name>
                  <b-icon-camera-fill />
                </template>
              </b-form-file>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group>
              <b-form-checkbox v-model="tmpItem.attivo">
                Attivo
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="EAN">
              <b-form-input v-model="tmpItem.codiceEan" />
            </b-form-group>
          </b-col>

          <b-col
            ><b-form-group label="Codice interno">
              <b-form-input v-model="tmpItem.codiceInterno" /> </b-form-group
          ></b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-skeleton v-if="loadingProductType" width="85%"></b-skeleton>
            <b-form-group v-else label="Tipo Prodotto">
              <b-form-select
                v-model="tmpItem.idProductType"
                :options="productTypeOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Nome prodotto">
              <b-form-input v-model="tmpItem.nomeProdotto" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Descrizione ">
              <b-form-textarea
                v-model="tmpItem.descrizione"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Quantità confezione">
              <b-form-input v-model="tmpItem.quantitaConfezione" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Collo orignale">
              <b-form-input type="number" v-model="tmpItem.colloOriginale" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Prezzo pubblico">
              <b-form-input type="number" v-model="tmpItem.prezzoPubblico" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="IVA">
              <b-form-input type="number" v-model="tmpItem.iva" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="mt-5 mb-5">
              <b-button
                v-if="loadingItems"
                squared
                variant="primary"
                size="lg"
                class="big-button"
                disabled
                >Caricamento in corso...</b-button
              >

              <b-button
                v-else
                squared
                variant="primary"
                size="lg"
                class="big-button"
                @click="saveItem"
                >Confirm</b-button
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </b-sidebar>
    <b-modal
      v-model="showDeleteModal"
      title="Attention!"
      ok-title="Confirm"
      cancel-title="Cancel"
      @hidden="resetData"
      @ok="deleteItem(currentItem.id)"
    >
      <span v-if="currentItem">Do you really want to delete?</span>
    </b-modal>
  </div>
</template>

<script>
  import AxiosService from "@/axiosServices/AxiosService.js";
  import SkeletonTable from "../../components/SkeletonTable.vue";
  export default {
    name: "ProdottiYoube",
    components: { SkeletonTable },
    data() {
      return {
        loadingItems: false,
        items: [],
        fields: [
          { key: "codiceEan", label: "EAN" },
          { key: "immagineProdotto", label: "Immagine prodotto" },
          { key: "nomeProdotto", label: "Nome Prodotto" },

          { key: "quantitaConfezione", label: "Quantità confezione" },
          { key: "prezzoPubblico", label: "Prezzo Pubblico" },
          { key: "iva", label: "IVA" },
          { key: "colloOriginale", label: "Collo originale" },
          { key: "attivo", label: "Attivo" },
          { key: "actions", label: "Azioni" },
        ],
        view_lang: null,

        currentPage: 1,
        perPage: 10,
        filter: "",

        tmpItem: {
          id: 0,
          idProductType: 0,
          immagineProdotto: "",
          codiceEan: "",
          codiceInterno: "",
          nomeProdotto: "",
          quantitaConfezione: "",
          descrizione: "",
          prezzoPubblico: 0,
          iva: 0,
          colloOriginale: 0,
          attivo: true,
        },
        currentItem: {},

        tmpImage: null,

        showDeleteModal: false,

        loadingProductType: false,
        productTypeOptions: [],
      };
    },
    serviceGET: null,
    servicePOST: null,
    servicePUT: null,
    serviceDELETE: null,
    created() {
      this.serviceGET = new AxiosService("Ecommerce/GetProducts");
      this.servicePOST = new AxiosService("Ecommerce/AddProduct");
      this.servicePUT = new AxiosService("Ecommerce/EditProduct");
      this.serviceDELETE = new AxiosService("Ecommerce/DeleteProduct");

      this.resetData();

      this.getData();
      this.getProductTypeOptions();
    },
    mounted() {
      // this.service.read().then((data) => {
      //   this.items = data;
      // });
      // this.service
      //   .getOptions("Company")
      //   .then((data) => (this.companyList = data));
    },
    methods: {
      getProductTypeOptions() {
        this.loadingProductType = true;
        let service = new AxiosService("Ecommerce/GetProductTypes");
        service
          .read()
          .then((res) => {
            res.forEach((element) => {
              this.productTypeOptions.push({
                text: element.nome,
                value: element.id,
              });
            });
          })
          .finally(() => {
            this.loadingProductType = false;
          });
      },
      getData() {
        this.loadingItems = true;
        this.resetData();

        this.serviceGET
          .read()
          .then((res) => {
            this.items = res;
          })
          .finally(() => {
            this.loadingItems = false;
          });
      },
      resetData() {
        this.tmpItem = {
          id: 0,
          idProductType: 0,
          immagineProdotto: "",
          codiceEan: "",
          codiceInterno: "",
          nomeProdotto: "",
          quantitaConfezione: "",
          descrizione: "",
          prezzoPubblico: 0,
          iva: 0,
          colloOriginale: 0,
          attivo: true,
        };
      },
      async saveItem() {
        this.loadingItems = true;
        let item = { ...this.tmpItem };

        if (this.tmpImage) {
          const formData = new FormData();
          formData.append("pic", this.tmpImage);

          this.servicePOST
            .uploadFile(formData)
            .then((res) => {
              item.immagineProdotto = this.$driveAddress + res.data;
            })
            .finally(() => {
              if (item.id) {
                this.servicePUT
                  .update(item)
                  .then(() => {
                    this.$root.$emit("bv::toggle::collapse", "sidebar-product");
                    this.getData();
                    this.$successToast();
                  })
                  .catch(() => {
                    this.$errorToast();
                    return false;
                  })
                  .finally(() => {
                    this.item = {};
                    this.loadingItems = false;
                  });
              } else {
                this.servicePOST
                  .create(item)
                  .then((response) => {
                    item.id = response.id;
                    this.items.push(item);
                    this.$root.$emit("bv::toggle::collapse", "sidebar-product");
                    this.getData();
                    this.$successToast();
                  })
                  .catch(() => {
                    this.$errorToast();
                    return false;
                  })
                  .finally(() => {
                    this.item = {};
                    this.loadingItems = false;
                  });
              }
            });
        } else {
          if (item.id) {
            this.servicePUT
              .update(item)
              .then(() => {
                this.$root.$emit("bv::toggle::collapse", "sidebar-product");
                this.getData();
                this.$successToast();
              })
              .catch(() => {
                this.$errorToast();
                return false;
              })
              .finally(() => {
                this.loadingItems = false;
              });
          } else {
            this.servicePOST
              .create(item)
              .then((response) => {
                item.id = response.id;
                this.items.push(item);
                this.$root.$emit("bv::toggle::collapse", "sidebar-product");
                this.getData();
                this.$successToast();
              })
              .catch(() => {
                this.$errorToast();
                return false;
              })
              .finally(() => {
                this.loadingItems = false;
              });
          }
        }
      },
      backgroundImage() {
        return (
          this.tmpItem.immagineProdotto ||
          "../../../public/images/placeholder-product.jpg"
        );
      },
      deleteItem(id) {
        this.loadingItems = true;
        this.serviceDELETE
          .delete(id)
          .then((response) => {
            if (response) {
              this.items = this.items.filter(
                (currentItem) => id !== currentItem.id
              );
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          })
          .finally(() => {
            this.loadingItems = false;
          });
      },
    },
    computed: {
      totalRows() {
        return this.filteredItems ? this.filteredItems.length : 0;
      },
      filterCompanyList() {
        return [{ value: null, text: "All companies" }, ...this.companyList];
      },

      filteredItems() {
        if (this.items) {
          return this.items.filter((item) => {
            if (item.nomeProdotto.includes(this.filter)) {
              return true;
            }
          });
        } else {
          return [];
        }
      },
    },
    watch: {
      filter: {
        handler() {
          this.currentPage = 1;
        },
      },
    },
  };
</script>
